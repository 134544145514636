import React from "react";

import Layout from "../components/Layout";

const AboutPage = () => (
    <Layout>
        <div id="main">
            <div className="about_section_banner">
                <div className="about_banner">
                    <h1>Acerca de FOXGLOBE</h1>
                </div>
            </div>
            <div className="inner">
                <div className="about_section_1">
                    <h1 className="about_section_1__title">Conoce más de nosotros</h1>
                    <p className="about_section_1__paragraph">
                        Empresa líder en la industria de la información.
                    </p>
                    <h1 className="about_section_1__title">Misión</h1>
                    <p className="about_section_1__paragraph">
                        Desarrollar y proveer productos y soluciones integrales de información académica, científica y
                        cultural, mediante recursos electrónicos, libros impresos, herramientas tecnológicas, educación,
                        proyectos culturales, infraestructura e innovación continua.
                    </p>
                    <h1 className="about_section_1__title">Visión</h1>
                    <p className="about_section_1__paragraph">
                        Consolidar el crecimiento sostenible de la organización y posicionar nuestro liderazgo en
                        América Latina, con talento humano experto y estándares de calidad certificada.
                    </p>
                    <h1 className="about_section_1__title">Valores</h1>
                    <p className="about_section_1__paragraph">
                        <p>
                            <strong>LIDERAZGO</strong> - Nos distinguimos por realizar nuestro trabajo de manera
                            ejemplar, con excelencia, pasión y actitud de servicio.
                        </p>
                        <p>
                            <strong>CALIDAD</strong> - Realizamos nuestras actividades correctamente, con disciplina, a
                            la primera y cuidando hasta el mínimo detalle.
                        </p>
                        <p>
                            <strong>COMPROMISO</strong> - Generamos confianza al cumplir nuestras responsabilidades con
                            proactividad y bajo cualquier circunstancia.
                        </p>
                        <p>
                            <strong>HONESTIDAD</strong> - Trabajamos en armonía y respeto, hablamos con la verdad y
                            somos leales con la empresa, nuestros clientes y proveedores.
                        </p>
                        <p>
                            <strong>TRABAJO EN EQUIPO</strong> - Sumamos esfuerzos y talentos para lograr nuestros
                            objetivos.
                        </p>
                        <p>
                            <strong>CREATIVIDAD</strong> - Aportamos ideas que generan soluciones innovadoras.
                        </p>
                    </p>
                </div>
            </div>
        </div>
    </Layout>
);

export default AboutPage;
